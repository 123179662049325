import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Dil dosyalarını içe aktar
import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'tr'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'localStorage'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      checkWhitelist: true
    },
    load: 'languageOnly',
    whitelist: ['en', 'tr'],
    nonExplicitWhitelist: true,
    // Sadece Türkçe tarayıcılar için Türkçe göster, diğerleri için İngilizce
    languageDetector: {
      lookup: (options) => {
        const navigatorLang = navigator.language.split('-')[0];
        return navigatorLang === 'tr' ? 'tr' : 'en';
      }
    }
  });

export default i18n; 