import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import { Card } from "./ui/card";
import { X } from 'lucide-react';
import { categoryTranslations } from '../translations/categoryMappings';

function CategoryPage() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { categoryId } = useParams();
  const [isGrayscale, setIsGrayscale] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [saturation, setSaturation] = useState(100);
  const [brightness, setBrightness] = useState(100);
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});

  // Güvenli localStorage işlemleri
  const safeStorage = {
    set: (key, value) => {
      try {
        // Önce eski verileri temizle
        const cleanStorage = () => {
          const keys = Object.keys(localStorage);
          const now = Date.now();
          let deletedCount = 0;

          // Önce 1 saatten eski verileri temizle
          keys.forEach(k => {
            if (k.includes('_timestamp')) {
              const timestamp = parseInt(localStorage.getItem(k));
              if (now - timestamp > 3600000) { // 1 saat
                const baseKey = k.replace('_timestamp', '');
                localStorage.removeItem(baseKey);
                localStorage.removeItem(k);
                deletedCount++;
              }
            }
          });

          // Hala yer açılmadıysa, en eski verileri sil
          if (deletedCount === 0) {
            const timestamps = keys
              .filter(k => k.includes('_timestamp'))
              .map(k => ({
                key: k.replace('_timestamp', ''),
                time: parseInt(localStorage.getItem(k))
              }))
              .sort((a, b) => a.time - b.time);

            // En eski 5 veriyi sil
            timestamps.slice(0, 5).forEach(item => {
              localStorage.removeItem(item.key);
              localStorage.removeItem(item.key + '_timestamp');
            });
          }
        };

        // Veriyi optimize et
        const optimizeData = (data) => {
          if (Array.isArray(data)) {
            // Sadece gerekli alanları tut
            return data.map(item => ({
              _id: item._id,
              murallsWp: { code: item.murallsWp?.code },
              imageUrls: item.imageUrls ? [item.imageUrls[0]] : [],
              bestseller: item.bestseller
            }));
          }
          return data;
        };

        // Veriyi kaydetmeyi dene
        const optimizedValue = optimizeData(value);
        const serializedValue = JSON.stringify(optimizedValue);
        
        try {
          localStorage.setItem(key, serializedValue);
        } catch (e) {
          if (e.name === 'QuotaExceededError') {
            cleanStorage();
            localStorage.setItem(key, serializedValue);
          } else {
            throw e;
          }
        }

        // Timestamp'i kaydet
        localStorage.setItem(key + '_timestamp', Date.now().toString());
        return true;
      } catch (error) {
        console.warn('Storage error:', error);
        return false;
      }
    },
    get: (key) => {
      try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.warn('Storage error:', error);
        return null;
      }
    }
  };

  const processImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return '/placeholder.jpg';
    
    if (originalUrl.startsWith('http')) {
      return originalUrl;
    }
    
    return `${process.env.REACT_APP_API_URL}${originalUrl}`;
  }, []);

  // Kategori ismini çeviren yardımcı fonksiyon
  const translateCategoryName = useCallback((name) => {
    return categoryTranslations[i18n.language]?.[name] || name;
  }, [i18n.language]);

  // Kategori bilgilerini ve ürünleri çek
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Önbellekten verileri kontrol et
        const cachedProducts = safeStorage.get(`category_${categoryId}_products`);
        const cachedCategoryInfo = safeStorage.get(`category_${categoryId}_info`);
        const cachedCategories = safeStorage.get('categories');
        const cachedCategoryImages = safeStorage.get('categoryImages');
        const cacheTimestamp = safeStorage.get(`category_${categoryId}_timestamp`);
        
        // Önbellekteki veri 1 saatten yeni ise kullan
        const isRecentCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp)) < 3600000;

        if (cachedProducts && cachedCategoryInfo && cachedCategories && cachedCategoryImages && isRecentCache) {
          setProducts(cachedProducts);
          setFilteredProducts(cachedProducts);
          setCategoryInfo(cachedCategoryInfo);
          setCategories(cachedCategories);
          setCategoryImages(cachedCategoryImages);
          setLoading(false);
          return; // Önbellekten veri varsa diğer işlemleri yapma
        }

        // Önce kategori bilgisini ve ürünleri getir
        const [categoryResponse, productsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${categoryId}`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${categoryId}/products`)
        ]);

        // Kategori bilgilerini güncelle
        setCategoryInfo(categoryResponse.data);

        // Önce bestseller'ları ve yeni ürünleri üste al
        const sortedProducts = productsResponse.data.sort((a, b) => {
          // Önce bestseller kontrolü
          if (a.bestseller && !b.bestseller) return -1;
          if (!a.bestseller && b.bestseller) return 1;
          
          // Sonra tarihe göre sıralama (yeniden eskiye)
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setProducts(sortedProducts);
        setFilteredProducts(sortedProducts);
        setLoading(false);

        // Arka planda diğer verileri yükle
        const fetchRestData = async () => {
          try {
            // Tüm kategorileri getir
            const categoriesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories`);
            setCategories(categoriesResponse.data);

            // Her kategori için ilk ürünün görselini al
            const newCategoryImages = {};
            await Promise.all(
              categoriesResponse.data.map(async (category) => {
                try {
                  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${category._id}/products`);
                  if (response.data.length > 0) {
                    const firstProduct = response.data[0];
                    if (firstProduct.imageUrls && firstProduct.imageUrls.length > 0) {
                      const imageUrl = firstProduct.imageUrls[0];
                      if (typeof imageUrl === 'string') {
                        if (imageUrl.startsWith('http')) {
                          newCategoryImages[category._id] = imageUrl;
                        } else {
                          const urlParts = imageUrl.split('/');
                          const fileName = urlParts[urlParts.length - 1];
                          newCategoryImages[category._id] = `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
                        }
                      } else if (typeof imageUrl === 'object' && imageUrl.url) {
                        if (imageUrl.url.startsWith('http')) {
                          newCategoryImages[category._id] = imageUrl.url;
                        } else {
                          const urlParts = imageUrl.url.split('/');
                          const fileName = urlParts[urlParts.length - 1];
                          newCategoryImages[category._id] = `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
                        }
                      }
                      // Her görsel hazır olduğunda state'i güncelle
                      setCategoryImages(prev => ({
                        ...prev,
                        [category._id]: newCategoryImages[category._id]
                      }));
                    }
                  }
                } catch (error) {
                  console.error(`Error fetching products for category ${category._id}:`, error);
                }
              })
            );

            // Verileri önbelleğe kaydet
            safeStorage.set(`category_${categoryId}_products`, sortedProducts);
            safeStorage.set(`category_${categoryId}_info`, categoryResponse.data);
            safeStorage.set('categories', categoriesResponse.data);
            safeStorage.set('categoryImages', newCategoryImages);
            safeStorage.set(`category_${categoryId}_timestamp`, Date.now());
          } catch (error) {
            console.error('Error fetching additional data:', error);
          }
        };

        // Arka planda veri yüklemeyi başlat
        fetchRestData();

      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId, processImageUrl]);

  // Arama filtrelemesi
  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (!query) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => 
        product.murallsWp?.code?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [products]);

  const getImageUrl = useCallback((product) => {
    if (!product.imageUrls || !Array.isArray(product.imageUrls) || product.imageUrls.length === 0) {
      return '/placeholder.jpg';
    }

    // Son görseli al
    for (let i = product.imageUrls.length - 1; i >= 0; i--) {
      const imageUrl = product.imageUrls[i];
      if (!imageUrl) continue;

      if (typeof imageUrl === 'string') {
        if (imageUrl.startsWith('http')) {
          return imageUrl;
        }
        
        const urlParts = imageUrl.split('/');
        const fileName = urlParts[urlParts.length - 1];
        
        return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
      } else if (typeof imageUrl === 'object' && imageUrl.url) {
        if (imageUrl.url.startsWith('http')) {
          return imageUrl.url;
        }
        
        const urlParts = imageUrl.url.split('/');
        const fileName = urlParts[urlParts.length - 1];
        
        return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
      }
    }

    return '/placeholder.jpg';
  }, []);

  // İlk görseli getiren fonksiyon (grid için)
  const getFirstImageUrl = useCallback((product) => {
    if (!product.imageUrls || !Array.isArray(product.imageUrls) || product.imageUrls.length === 0) {
      return '/placeholder.jpg';
    }

    const imageUrl = product.imageUrls[0];
    if (!imageUrl) return '/placeholder.jpg';

    if (typeof imageUrl === 'string') {
      if (imageUrl.startsWith('http')) {
        return imageUrl;
      }
      
      const urlParts = imageUrl.split('/');
      const fileName = urlParts[urlParts.length - 1];
      
      return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
    } else if (typeof imageUrl === 'object' && imageUrl.url) {
      if (imageUrl.url.startsWith('http')) {
        return imageUrl.url;
      }
      
      const urlParts = imageUrl.url.split('/');
      const fileName = urlParts[urlParts.length - 1];
      
      return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
    }

    return '/placeholder.jpg';
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    document.body.style.overflow = 'unset';
    setIsGrayscale(false);
    setIsFlipped(false);
    setSaturation(100);
    setBrightness(100);
  };

  const handleResetSettings = () => {
    setIsGrayscale(false);
    setIsFlipped(false);
    setSaturation(100);
    setBrightness(100);
  };

  const getSettingsString = useCallback(() => {
    const settings = [];
    if (isGrayscale) settings.push('grayscale');
    if (isFlipped) settings.push('flip');
    if (saturation !== 100) settings.push(`saturation${saturation}`);
    if (brightness !== 100) settings.push(`brightness${brightness}`);
    return settings.join('_') || 'normal';
  }, [isGrayscale, isFlipped, saturation, brightness]);

  const handleCopySettings = async () => {
    const settingsText = getSettingsString();
    try {
      await navigator.clipboard.writeText(settingsText);
      alert(t('design.settingsCopied'));
    } catch (err) {
      alert(t('design.copyError'));
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header 
        onSearch={handleSearch} 
        categories={categories}
        categoryImages={categoryImages}
      />
      <main className="px-4 max-w-[2000px] mx-auto">
        {/* Kategori Başlığı */}
        {categoryInfo && (
          <div className="mt-10 mb-4">
            <div className="bg-white">
              <div className="max-w-2xl mx-auto text-center py-6 px-4">
                <div className="inline-flex items-center space-x-2">
                  <div className="h-[2px] w-8 bg-zinc-300"></div>
                  <span className="text-xs uppercase tracking-wider text-zinc-400 font-medium">Kategori</span>
                  <div className="h-[2px] w-8 bg-zinc-300"></div>
                </div>
                <h1 className="text-2xl md:text-3xl font-bold text-zinc-800 mt-2">
                  {translateCategoryName(categoryInfo.name)}
                </h1>
                {categoryInfo.description && (
                  <p className="mt-2 text-zinc-500 text-sm max-w-xl mx-auto">
                    {categoryInfo.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="pt-0">
          {loading ? (
            <div className="flex items-center justify-center py-10">
              <div className="text-lg">{t('common.loading')}</div>
            </div>
          ) : filteredProducts.length === 0 ? (
            <div className="flex items-center justify-center py-10">
              <div className="text-lg text-gray-500">
                {searchQuery ? t('search.noResults') : t('common.noProducts')}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-3">
              {filteredProducts.map(product => (
                <div key={product._id} onClick={() => handleProductClick(product)} className="cursor-pointer">
                  <Card className="overflow-hidden group">
                    <div className="relative aspect-[3/2]">
                      <img
                        src={getFirstImageUrl(product)}
                        alt={product.name}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.jpg';
                        }}
                      />
                      {product.bestseller && (
                        <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-full text-xs font-medium">
                          {t('common.bestseller')}
                        </div>
                      )}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                          <div className="text-white text-sm font-medium">
                            {product.murallsWp?.code}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Modal for enlarged product view */}
        {selectedProduct && (
          <div className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center p-4 backdrop-blur-sm" onClick={handleCloseModal}>
            <div className="relative max-w-7xl w-full bg-gradient-to-b from-zinc-900 to-black rounded-2xl overflow-hidden shadow-2xl border border-white/10" onClick={e => e.stopPropagation()}>
              {/* Close Button */}
              <button 
                onClick={handleCloseModal}
                className="absolute top-4 right-4 z-10 bg-white hover:bg-white/90 text-black rounded-full p-3 transition-colors shadow-lg lg:bg-white/10 lg:hover:bg-white/20 lg:text-white lg:p-2"
              >
                <X className="w-8 h-8 lg:w-6 lg:h-6" />
              </button>

              {/* Split View Container */}
              <div className="flex flex-col lg:flex-row max-h-[90vh] lg:h-[90vh] overflow-y-auto lg:overflow-y-hidden">
                {/* Left Side - Image */}
                <div className="flex-1 relative flex items-center justify-center p-4 bg-gradient-to-br from-zinc-900/50 to-black/50">
                  <img
                    src={getImageUrl(selectedProduct)}
                    alt={selectedProduct.name}
                    className="max-h-[40vh] lg:max-h-full w-auto object-contain rounded-lg shadow-2xl"
                    style={{
                      filter: `
                        ${isGrayscale ? 'grayscale(100%)' : ''}
                        saturate(${saturation}%)
                        brightness(${brightness}%)
                      `,
                      transform: isFlipped ? 'scaleX(-1)' : 'none'
                    }}
                  />
                </div>

                {/* Right Side - Controls */}
                <div className="w-full lg:w-96 bg-gradient-to-b from-zinc-900/80 to-black/80 p-6 lg:p-8 flex flex-col gap-6 lg:border-l lg:border-white/10">
                  {/* Product Code */}
                  <div className="text-center bg-white/5 rounded-xl p-4">
                    <h3 className="text-lg font-medium text-white/80 mb-1">
                      {t('common.productCode')}
                    </h3>
                    <div className="text-3xl font-bold text-white">
                      {selectedProduct.murallsWp?.code}
                    </div>
                  </div>

                  {/* Image Controls */}
                  <div className="space-y-6">
                    <h3 className="text-lg font-medium text-white/80">
                      {t('design.imageSettings')}
                    </h3>

                    {/* Toggle Buttons */}
                    <div className="grid grid-cols-2 gap-3">
                      {/* Grayscale Toggle */}
                      <button
                        onClick={() => setIsGrayscale(!isGrayscale)}
                        className={`px-4 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                          isGrayscale
                            ? 'bg-white text-black shadow-lg'
                            : 'bg-white/5 text-white hover:bg-white/10 border border-white/10'
                        }`}
                      >
                        {isGrayscale ? t('design.color') : t('design.grayscale')}
                      </button>

                      {/* Flip Toggle */}
                      <button
                        onClick={() => setIsFlipped(!isFlipped)}
                        className={`px-4 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                          isFlipped
                            ? 'bg-white text-black shadow-lg'
                            : 'bg-white/5 text-white hover:bg-white/10 border border-white/10'
                        }`}
                      >
                        {isFlipped ? t('design.normal') : t('design.mirror')}
                      </button>
                    </div>

                    {/* Sliders */}
                    <div className="space-y-6">
                      {/* Saturation Control */}
                      <div className="space-y-2">
                        <div className="flex justify-between text-sm text-white/80">
                          <span>{t('design.saturation')}</span>
                          <span>{saturation}%</span>
                        </div>
                        <input
                          type="range"
                          min="0"
                          max="200"
                          value={saturation}
                          onChange={(e) => setSaturation(parseInt(e.target.value))}
                          className="w-full h-2 bg-white/5 rounded-lg appearance-none cursor-pointer accent-white hover:bg-white/10 transition-colors"
                        />
                      </div>

                      {/* Brightness Control */}
                      <div className="space-y-2">
                        <div className="flex justify-between text-sm text-white/80">
                          <span>{t('design.brightness')}</span>
                          <span>{brightness}%</span>
                        </div>
                        <input
                          type="range"
                          min="0"
                          max="200"
                          value={brightness}
                          onChange={(e) => setBrightness(parseInt(e.target.value))}
                          className="w-full h-2 bg-white/5 rounded-lg appearance-none cursor-pointer accent-white hover:bg-white/10 transition-colors"
                        />
                      </div>
                    </div>

                    {/* Reset Button */}
                    <button
                      onClick={handleResetSettings}
                      className="w-full px-4 py-3 rounded-xl text-sm font-medium bg-white/5 text-white hover:bg-white/10 transition-all duration-200 flex items-center justify-center gap-2 border border-white/10"
                    >
                      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                      {t('design.resetSettings')}
                    </button>

                    {/* Divider */}
                    <div className="border-t border-white/10"></div>

                    {/* Copy Settings Button */}
                    <button
                      onClick={handleCopySettings}
                      className="w-full px-4 py-3 rounded-xl text-sm font-medium bg-white text-black hover:bg-white/90 transition-all duration-200 flex items-center justify-center gap-2 shadow-lg"
                    >
                      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                      </svg>
                      {t('design.copySettings')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default CategoryPage;