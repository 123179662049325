   import React from 'react';
   import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
   import HomePage from './components/HomePage';
   import CategoryPage from './components/CategoryPage';
   import axios from 'axios';

// Axios default ayarları
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

   function App() {
     return (
       <Router>
         <Routes>
           <Route path="/" element={<HomePage />} />
           <Route path="/category/:categoryId" element={<CategoryPage />} />
         </Routes>
       </Router>
     );
   }

   export default App;