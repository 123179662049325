export const categoryTranslations = {
  tr: {
    "MERMER DUVAR KAĞIDI": "MERMER DUVAR KAĞIDI",
    "ÇİÇEK DUVAR KAĞIDI": "ÇİÇEK DUVAR KAĞIDI",
    "YAPRAK DUVAR KAĞIDI": "YAPRAK DUVAR KAĞIDI",
    "MANZARA DUVAR KAĞIDI": "MANZARA DUVAR KAĞIDI",
    "[PH] Sanat Poster Hanger": "SANAT POSTER",
    "ÇİZGİLİ DUVAR KAĞIDI": "ÇİZGİLİ DUVAR KAĞIDI",
    "TAŞ DUVAR KAĞIDI": "TAŞ DUVAR KAĞIDI",
    "ŞEHİR DUVAR KAĞIDI": "ŞEHİR DUVAR KAĞIDI",
    "3D DUVAR KAĞIDI": "3D DUVAR KAĞIDI",
    "ÖZEL GÜNLER DUVAR KAĞIDI": "ÖZEL GÜNLER DUVAR KAĞIDI",
    "CHINOISERIE DUVAR KAĞIDI": "CHINOISERIE DUVAR KAĞIDI",
    "ÇOCUK HARİTA DUVAR KAĞIDI": "ÇOCUK HARİTA DUVAR KAĞIDI",
    "AHŞAP DUVAR KAĞIDI": "AHŞAP DUVAR KAĞIDI",
    "POPART DUVAR KAĞIDI": "POPART DUVAR KAĞIDI",
    "DAĞ DUVAR KAĞIDI": "DAĞ DUVAR KAĞIDI",
    "TÜY DUVAR KAĞIDI": "TÜY DUVAR KAĞIDI",
    "LUXURY DUVAR KAĞIDI": "LUXURY DUVAR KAĞIDI",
    "SOYUT DUVAR KAĞIDI": "SOYUT DUVAR KAĞIDI",
    "SPOR DUVAR KAĞIDI": "SPOR DUVAR KAĞIDI",
    "GÖKKUŞAĞI DUVAR KAĞIDI": "GÖKKUŞAĞI DUVAR KAĞIDI",
    "SULUBOYA DUVAR KAĞIDI": "SULUBOYA DUVAR KAĞIDI",
    "HARİTA DUVAR KAĞIDI": "HARİTA DUVAR KAĞIDI",
    " TILE DUVAR KAĞIDI": "MOZAİK DUVAR KAĞIDI",
    "SİS DUVAR KAĞIDI": "SİS DUVAR KAĞIDI",
    "ARAÇ DUVAR KAĞIDI": "ARAÇ DUVAR KAĞIDI",
    " GRAFITI DUVAR KAĞIDI": "GRAFİTİ DUVAR KAĞIDI",
    "UZAY DUVAR KAĞIDI": "UZAY DUVAR KAĞIDI",
    "SIVA DUVAR KAĞIDI": "SIVA DUVAR KAĞIDI",
    "VİNTAGE DUVAR KAĞIDI": "VINTAGE DUVAR KAĞIDI",
    "[STICKER] ÇOCUK": "STICKER ÇOCUK",
    "ÇOCUK DUVAR KAĞIDI": "ÇOCUK DUVAR KAĞIDI",
    "GEOMETRİC DUVAR KAĞIDI": "GEOMETRİC DUVAR KAĞIDI",
    "TROPİKAL DUVAR KAĞIDI": "TROPİKAL DUVAR KAĞIDI",
    "DİNOZOR DUVAR KAĞIDI": "DİNOZOR DUVAR KAĞIDI",
    "SANAT DUVAR KAĞIDI": "SANAT DUVAR KAĞIDI",
    "HAYVAN DUVAR KAĞIDI": "HAYVAN DUVAR KAĞIDI",
    "YEMEK DUVAR KAĞIDI": "YEMEK DUVAR KAĞIDI"
  },
  en: {
    "MERMER DUVAR KAĞIDI": "MARBLE WALLPAPER",
    "ÇİÇEK DUVAR KAĞIDI": "FLOWER WALLPAPER",
    "YAPRAK DUVAR KAĞIDI": "LEAF WALLPAPER",
    "MANZARA DUVAR KAĞIDI": "LANDSCAPE WALLPAPER",
    "[PH] Sanat Poster Hanger": "ART POSTER HANGER",
    "ÇİZGİLİ DUVAR KAĞIDI": "STRIPED WALLPAPER",
    "TAŞ DUVAR KAĞIDI": "STONE WALLPAPER",
    "ŞEHİR DUVAR KAĞIDI": "CITY WALLPAPER",
    "3D DUVAR KAĞIDI": "3D WALLPAPER",
    "ÖZEL GÜNLER DUVAR KAĞIDI": "SPECIAL DAYS WALLPAPER",
    "CHINOISERIE DUVAR KAĞIDI": "CHINOISERIE WALLPAPER",
    "ÇOCUK HARİTA DUVAR KAĞIDI": "KIDS MAP WALLPAPER",
    "AHŞAP DUVAR KAĞIDI": "WOODEN WALLPAPER",
    "POPART DUVAR KAĞIDI": "POP ART WALLPAPER",
    "DAĞ DUVAR KAĞIDI": "MOUNTAIN WALLPAPER",
    "TÜY DUVAR KAĞIDI": "FEATHER WALLPAPER",
    "LUXURY DUVAR KAĞIDI": "LUXURY WALLPAPER",
    "SOYUT DUVAR KAĞIDI": "ABSTRACT WALLPAPER",
    "SPOR DUVAR KAĞIDI": "SPORTS WALLPAPER",
    "GÖKKUŞAĞI DUVAR KAĞIDI": "RAINBOW WALLPAPER",
    "SULUBOYA DUVAR KAĞIDI": "WATERCOLOR WALLPAPER",
    "HARİTA DUVAR KAĞIDI": "MAP WALLPAPER",
    " TILE DUVAR KAĞIDI": "TILE WALLPAPER",
    "SİS DUVAR KAĞIDI": "FOG WALLPAPER",
    "ARAÇ DUVAR KAĞIDI": "VEHICLE WALLPAPER",
    " GRAFITI DUVAR KAĞIDI": "GRAFFITI WALLPAPER",
    "UZAY DUVAR KAĞIDI": "SPACE WALLPAPER",
    "SIVA DUVAR KAĞIDI": "GRUNGE WALLPAPER",
    "VİNTAGE DUVAR KAĞIDI": "VINTAGE WALLPAPER",
    "[STICKER] ÇOCUK": "KIDS STICKER",
    "ÇOCUK DUVAR KAĞIDI": "KIDS WALLPAPER",
    "GEOMETRİC DUVAR KAĞIDI": "GEOMETRIC WALLPAPER",
    "TROPİKAL DUVAR KAĞIDI": "TROPICAL WALLPAPER",
    "DİNOZOR DUVAR KAĞIDI": "DINOSAUR WALLPAPER",
    "SANAT DUVAR KAĞIDI": "ART WALLPAPER",
    "HAYVAN DUVAR KAĞIDI": "ANIMAL WALLPAPER",
    "YEMEK DUVAR KAĞIDI": "FOOD WALLPAPER"
  }
};

// Kategori sıralama bilgisi
export const categoryOrder = {
  "ÇİÇEK DUVAR KAĞIDI": 1,        // En popüler kategori
  "MERMER DUVAR KAĞIDI": 2,       // Lüks ve popüler
  "YAPRAK DUVAR KAĞIDI": 3,       // Doğal ve trend
  "MANZARA DUVAR KAĞIDI": 4,      // Klasik tercih
  "3D DUVAR KAĞIDI": 5,           // Modern ve dikkat çekici
  "LUXURY DUVAR KAĞIDI": 6,       // Premium segment
  "SOYUT DUVAR KAĞIDI": 7,        // Sanatsal ve modern
  "ÇOCUK DUVAR KAĞIDI": 8,        // Özel segment
  "TAŞ DUVAR KAĞIDI": 9,          // Klasik tercih
  "VİNTAGE DUVAR KAĞIDI": 10,     // Retro trend
  "GEOMETRİC DUVAR KAĞIDI": 11,   // Modern tasarım
  "TROPİKAL DUVAR KAĞIDI": 12,    // Egzotik tasarım
  "SULUBOYA DUVAR KAĞIDI": 13,    // Sanatsal
  "CHINOISERIE DUVAR KAĞIDI": 14, // Özel tasarım
  "AHŞAP DUVAR KAĞIDI": 15,       // Doğal görünüm
  "SANAT DUVAR KAĞIDI": 16,       // Özel koleksiyon
  "HARİTA DUVAR KAĞIDI": 17,      // Eğitici
  "ŞEHİR DUVAR KAĞIDI": 18,       // Modern
  " TILE DUVAR KAĞIDI": 19,        // Geometrik
  "HAYVAN DUVAR KAĞIDI": 20,      // Çocuk odası
  "POPART DUVAR KAĞIDI": 21,      // Modern sanat
  "DAĞ DUVAR KAĞIDI": 22,         // Doğa
  "TÜY DUVAR KAĞIDI": 23,         // Hafif desenli
  "SPOR DUVAR KAĞIDI": 24,        // Özel tema
  "GÖKKUŞAĞI DUVAR KAĞIDI": 25,   // Renkli
  "SİS DUVAR KAĞIDI": 26,         // Atmosferik
  "ARAÇ DUVAR KAĞIDI": 27,        // Özel tema
  " GRAFITI DUVAR KAĞIDI": 28,     // Modern
  "UZAY DUVAR KAĞIDI": 29,        // Özel tema
  "SIVA DUVAR KAĞIDI": 30,        // Endüstriyel
  "DİNOZOR DUVAR KAĞIDI": 31,     // Çocuk teması
  "YEMEK DUVAR KAĞIDI": 32,       // Mutfak
  "ÖZEL GÜNLER DUVAR KAĞIDI": 33, // Sezonluk
  "ÇİZGİLİ DUVAR KAĞIDI": 34,     // Klasik
  "ÇOCUK HARİTA DUVAR KAĞIDI": 35, // Eğitici çocuk
  "[PH] Sanat Poster Hanger": 36,  // Hanger - sona alındı
  "[STICKER] ÇOCUK": 37           // Sticker - sona alındı
}; 