import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import { Card } from "./ui/card";
import { ImageIcon, X } from 'lucide-react';
import { categoryTranslations, categoryOrder } from '../translations/categoryMappings';

function HomePage() {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isGrayscale, setIsGrayscale] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [saturation, setSaturation] = useState(100);
  const [brightness, setBrightness] = useState(100);
  const [searchQuery, setSearchQuery] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Güvenli localStorage işlemleri
  const safeStorage = {
    clearAll: () => {
      try {
        const keys = Object.keys(localStorage);
        keys.forEach(key => {
          if (key.startsWith('category_') || key === 'categories' || key === 'categoryImages' || key === 'allProducts') {
            localStorage.removeItem(key);
          }
        });
        return true;
      } catch (error) {
        console.warn('Storage clear error:', error);
        return false;
      }
    },
    set: (key, value) => {
      try {
        // Önce eski verileri temizle
        const cleanStorage = () => {
          const keys = Object.keys(localStorage);
          const now = Date.now();
          let deletedCount = 0;

          // Önce 1 saatten eski verileri temizle
          keys.forEach(k => {
            if (k.includes('_timestamp')) {
              const timestamp = parseInt(localStorage.getItem(k));
              if (now - timestamp > 3600000) { // 1 saat
                const baseKey = k.replace('_timestamp', '');
                localStorage.removeItem(baseKey);
                localStorage.removeItem(k);
                deletedCount++;
              }
            }
          });

          // Hala yer açılmadıysa, en eski verileri sil
          if (deletedCount === 0) {
            const timestamps = keys
              .filter(k => k.includes('_timestamp'))
              .map(k => ({
                key: k.replace('_timestamp', ''),
                time: parseInt(localStorage.getItem(k))
              }))
              .sort((a, b) => a.time - b.time);

            // En eski 5 veriyi sil
            timestamps.slice(0, 5).forEach(item => {
              localStorage.removeItem(item.key);
              localStorage.removeItem(item.key + '_timestamp');
            });
          }
        };

        // Veriyi optimize et
        const optimizeData = (data) => {
          if (Array.isArray(data)) {
            // Sadece gerekli alanları tut
            return data.map(item => ({
              _id: item._id,
              murallsWp: { code: item.murallsWp?.code },
              imageUrls: item.imageUrls ? [item.imageUrls[0]] : [],
              bestseller: item.bestseller
            }));
          }
          return data;
        };

        // Veriyi kaydetmeyi dene
        const optimizedValue = optimizeData(value);
        const serializedValue = JSON.stringify(optimizedValue);
        
        try {
          localStorage.setItem(key, serializedValue);
        } catch (e) {
          if (e.name === 'QuotaExceededError') {
            cleanStorage();
            localStorage.setItem(key, serializedValue);
          } else {
            throw e;
          }
        }

        // Timestamp'i kaydet
        localStorage.setItem(key + '_timestamp', Date.now().toString());
        return true;
      } catch (error) {
        console.warn('Storage error:', error);
        return false;
      }
    },
    get: (key) => {
      try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.warn('Storage error:', error);
        return null;
      }
    }
  };

  // Kategori ismini çeviren yardımcı fonksiyon
  const translateCategoryName = useCallback((name) => {
    return categoryTranslations[i18n.language]?.[name] || name;
  }, [i18n.language]);

  const processImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return null;
    
    if (originalUrl.startsWith('http')) {
      return originalUrl;
    }

    // URL'i parçalara ayır
    const urlParts = originalUrl.split('/');
    const fileName = urlParts[urlParts.length - 1];
    
    // Optimize edilmiş görüntü yolunu oluştur
    return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);

        // Önbelleği temizle
        safeStorage.clearAll();

        // Kategorileri getir
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories`);
        const sortedCategories = categoriesResponse.data.sort((a, b) => {
          const orderA = categoryOrder[a.name] || Number.MAX_SAFE_INTEGER;
          const orderB = categoryOrder[b.name] || Number.MAX_SAFE_INTEGER;
          return orderA - orderB;
        });
        
        // Hemen kategorileri göster
        setCategories(sortedCategories);
        setLoading(false);

        // Her kategori için ilk ürünün görselini al
        const newCategoryImages = {};
        await Promise.all(
          sortedCategories.map(async (category) => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${category._id}/products`);
              if (response.data.length > 0) {
                // Önce bestseller ürünleri bul
                const bestsellerProducts = response.data.filter(product => product.bestseller);
                
                // Eğer bestseller varsa rastgele bir bestseller seç, yoksa en son eklenen ürünü al
                let selectedProduct;
                if (bestsellerProducts.length > 0) {
                  const randomIndex = Math.floor(Math.random() * bestsellerProducts.length);
                  selectedProduct = bestsellerProducts[randomIndex];
                } else {
                  // Ürünleri tarihe göre sırala ve en son eklenen ürünü al
                  selectedProduct = response.data.sort((a, b) => 
                    new Date(b.createdAt) - new Date(a.createdAt)
                  )[0];
                }

                if (selectedProduct.imageUrls && selectedProduct.imageUrls.length > 0) {
                  const imageUrl = selectedProduct.imageUrls[0];
                  if (typeof imageUrl === 'string') {
                    if (imageUrl.startsWith('http')) {
                      newCategoryImages[category._id] = imageUrl;
                    } else {
                      const fileName = imageUrl.split('/').pop();
                      newCategoryImages[category._id] = `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
                    }
                  } else if (typeof imageUrl === 'object' && imageUrl.url) {
                    if (imageUrl.url.startsWith('http')) {
                      newCategoryImages[category._id] = imageUrl.url;
                    } else {
                      const fileName = imageUrl.url.split('/').pop();
                      newCategoryImages[category._id] = `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
                    }
                  }
                  // Her görsel hazır olduğunda state'i güncelle
                  setCategoryImages(prev => ({
                    ...prev,
                    [category._id]: newCategoryImages[category._id]
                  }));
                }
              }
            } catch (error) {
              console.error(`Error fetching products for category ${category._id}:`, error);
            }
          })
        );

        // Verileri önbelleğe kaydet
        safeStorage.set('categories', sortedCategories);
        safeStorage.set('categoryImages', newCategoryImages);
        safeStorage.set('cacheTimestamp', Date.now());

      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (!query) {
      setIsSearching(false);
      setFilteredProducts([]);
    } else {
      setIsSearching(true);
      const filtered = allProducts.filter(product => 
        product.murallsWp?.code?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [allProducts]);

  const getImageUrl = useCallback((product) => {
    if (!product.imageUrls || !Array.isArray(product.imageUrls) || product.imageUrls.length === 0) {
      return '/placeholder.jpg';
    }

    for (let i = product.imageUrls.length - 1; i >= 0; i--) {
      const imageUrl = product.imageUrls[i];
      if (!imageUrl) continue;

      if (typeof imageUrl === 'string') {
        if (imageUrl.startsWith('http')) {
          return imageUrl;
        }
        
        const urlParts = imageUrl.split('/');
        const fileName = urlParts[urlParts.length - 1];
        
        return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
      } else if (typeof imageUrl === 'object' && imageUrl.url) {
        if (imageUrl.url.startsWith('http')) {
          return imageUrl.url;
        }
        
        const urlParts = imageUrl.url.split('/');
        const fileName = urlParts[urlParts.length - 1];
        
        return `${process.env.REACT_APP_API_URL}/images/optimized/${fileName}`;
      }
    }

    return '/placeholder.jpg';
  }, []);

  const handleImageUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setUploadedImage(imageUrl);
  };

  const handleProductClick = (product) => {
    setUploadedImage(getImageUrl(product));
  };

  const handleCloseModal = () => {
    setUploadedImage(null);
    setIsGrayscale(false);
    setIsFlipped(false);
    setSaturation(100);
    setBrightness(100);
  };

  const handleResetSettings = () => {
    setIsGrayscale(false);
    setIsFlipped(false);
    setSaturation(100);
    setBrightness(100);
  };

  const getSettingsString = useCallback(() => {
    const settings = [];
    if (isGrayscale) settings.push('grayscale');
    if (isFlipped) settings.push('flip');
    if (saturation !== 100) settings.push(`saturation${saturation}`);
    if (brightness !== 100) settings.push(`brightness${brightness}`);
    return settings.join('_') || 'normal';
  }, [isGrayscale, isFlipped, saturation, brightness]);

  const handleCopySettings = async () => {
    const settingsText = getSettingsString();
    try {
      await navigator.clipboard.writeText(settingsText);
      alert(t('design.settingsCopied'));
    } catch (err) {
      alert(t('design.copyError'));
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-lg">{t('common.loading')}</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-lg text-red-600">{t('common.error')}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header 
        onImageUpload={handleImageUpload} 
        onSearch={handleSearch}
        categories={categories}
        categoryImages={categoryImages}
      />
      
      {/* Main Content */}
      <main className="px-6 py-8 pt-24 w-full">
        {loading ? (
          <div className="text-center text-gray-500">{t('common.loading')}</div>
        ) : error ? (
          <div className="text-center text-red-500">{t('common.error')}</div>
        ) : isSearching ? (
          filteredProducts.length === 0 ? (
            <div className="text-center text-gray-500">{t('search.noResults')}</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredProducts.map(product => (
                <div key={product._id} className="cursor-pointer" onClick={() => handleProductClick(product)}>
                  <Card className="overflow-hidden group">
                    <div className="relative aspect-[3/2]">
                      <img
                        src={getImageUrl(product)}
                        alt={product.name}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.jpg';
                        }}
                      />
                      {product.bestseller && (
                        <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-full text-xs font-medium">
                          {t('common.bestseller')}
                        </div>
                      )}
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                          <div className="text-white text-sm font-medium">
                            {product.murallsWp?.code}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          )
        ) : categories.length === 0 ? (
          <div className="text-center text-gray-500">{t('common.noProducts')}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {categories.map(category => (
              <Link key={category._id} to={`/category/${category._id}`}>
                <Card className="group relative h-[300px] overflow-hidden rounded-2xl transition-all duration-300 hover:shadow-2xl">
                  {/* Image Container */}
                  <div className="absolute inset-0">
                    {categoryImages[category._id] ? (
                      <img
                        src={categoryImages[category._id]}
                        alt={translateCategoryName(category.name)}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.style.display = 'none';
                          e.target.nextElementSibling.style.display = 'flex';
                        }}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-gray-100">
                        <ImageIcon className="w-12 h-12 text-gray-400" />
                      </div>
                    )}
                    
                    {/* Overlay with gradient */}
                    <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent opacity-60 transition-opacity duration-300 group-hover:opacity-75" />
                    
                    {/* Category Name */}
                    <div className="absolute inset-0 flex items-center p-8">
                      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6 transform scale-95 transition-transform duration-500 group-hover:scale-100">
                        <h2 className="text-2xl font-bold text-white mb-2">
                          {translateCategoryName(category.name)}
                        </h2>
                        {category.description && (
                          <p className="text-sm text-gray-200 opacity-0 transform translate-x-4 transition-all duration-500 group-hover:opacity-100 group-hover:translate-x-0">
                            {category.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        )}
      </main>

      {/* Image Editor Modal */}
      {uploadedImage && (
        <div className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center p-4 backdrop-blur-sm" onClick={handleCloseModal}>
          <div className="relative max-w-7xl w-full bg-gradient-to-b from-zinc-900 to-black rounded-2xl overflow-hidden shadow-2xl border border-white/10" onClick={e => e.stopPropagation()}>
            {/* Close Button */}
            <button 
              onClick={handleCloseModal}
              className="absolute top-4 right-4 z-10 bg-white hover:bg-white/90 text-black rounded-full p-3 transition-colors shadow-lg lg:bg-white/10 lg:hover:bg-white/20 lg:text-white lg:p-2"
            >
              <X className="w-8 h-8 lg:w-6 lg:h-6" />
            </button>

            {/* Split View Container */}
            <div className="flex flex-col lg:flex-row max-h-[90vh] lg:h-[90vh] overflow-y-auto lg:overflow-y-hidden">
              {/* Left Side - Image */}
              <div className="flex-1 relative flex items-center justify-center p-4 bg-gradient-to-br from-zinc-900/50 to-black/50">
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  className="max-h-[40vh] lg:max-h-full w-auto object-contain rounded-lg shadow-2xl"
                  style={{
                    filter: `
                      ${isGrayscale ? 'grayscale(100%)' : ''}
                      saturate(${saturation}%)
                      brightness(${brightness}%)
                    `,
                    transform: isFlipped ? 'scaleX(-1)' : 'none'
                  }}
                />
              </div>

              {/* Right Side - Controls */}
              <div className="w-full lg:w-96 bg-gradient-to-b from-zinc-900/80 to-black/80 p-6 lg:p-8 flex flex-col gap-6 lg:border-l lg:border-white/10">
                {/* Image Controls */}
                <div className="space-y-6">
                  <h3 className="text-lg font-medium text-white/80">
                    {t('design.imageSettings')}
                  </h3>

                  {/* Toggle Buttons */}
                  <div className="grid grid-cols-2 gap-3">
                    {/* Grayscale Toggle */}
                    <button
                      onClick={() => setIsGrayscale(!isGrayscale)}
                      className={`px-4 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                        isGrayscale
                          ? 'bg-white text-black shadow-lg'
                          : 'bg-white/5 text-white hover:bg-white/10 border border-white/10'
                      }`}
                    >
                      {isGrayscale ? t('design.color') : t('design.grayscale')}
                    </button>

                    {/* Flip Toggle */}
                    <button
                      onClick={() => setIsFlipped(!isFlipped)}
                      className={`px-4 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                        isFlipped
                          ? 'bg-white text-black shadow-lg'
                          : 'bg-white/5 text-white hover:bg-white/10 border border-white/10'
                      }`}
                    >
                      {isFlipped ? t('design.normal') : t('design.mirror')}
                    </button>
                  </div>

                  {/* Sliders */}
                  <div className="space-y-6">
                    {/* Saturation Control */}
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm text-white/80">
                        <span>{t('design.saturation')}</span>
                        <span>{saturation}%</span>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="200"
                        value={saturation}
                        onChange={(e) => setSaturation(parseInt(e.target.value))}
                        className="w-full h-2 bg-white/5 rounded-lg appearance-none cursor-pointer accent-white hover:bg-white/10 transition-colors"
                      />
                    </div>

                    {/* Brightness Control */}
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm text-white/80">
                        <span>{t('design.brightness')}</span>
                        <span>{brightness}%</span>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="200"
                        value={brightness}
                        onChange={(e) => setBrightness(parseInt(e.target.value))}
                        className="w-full h-2 bg-white/5 rounded-lg appearance-none cursor-pointer accent-white hover:bg-white/10 transition-colors"
                      />
                    </div>
                  </div>

                  {/* Reset Button */}
                  <button
                    onClick={handleResetSettings}
                    className="w-full px-4 py-3 rounded-xl text-sm font-medium bg-white/5 text-white hover:bg-white/10 transition-all duration-200 flex items-center justify-center gap-2 border border-white/10"
                  >
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    {t('design.resetSettings')}
                  </button>

                  {/* Divider */}
                  <div className="border-t border-white/10"></div>

                  {/* Copy Settings Button */}
                  <button
                    onClick={handleCopySettings}
                    className="w-full px-4 py-3 rounded-xl text-sm font-medium bg-white text-black hover:bg-white/90 transition-all duration-200 flex items-center justify-center gap-2 shadow-lg"
                  >
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    </svg>
                    {t('design.copySettings')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;