import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRFlag, GBFlag } from './icons/flags';
import { ImagePlus, Search, X, Menu, ChevronDown } from 'lucide-react';
import { categoryTranslations, categoryOrder } from '../translations/categoryMappings';

function Header({ onImageUpload, onSearch, categories = [], categoryImages = {} }) {
  const { t, i18n } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'English', flag: GBFlag },
    { code: 'tr', name: 'Türkçe', flag: TRFlag }
  ];

  // Kategorileri sıralı şekilde al
  const sortedCategories = categories
    .sort((a, b) => (categoryOrder[a.name] || 999) - (categoryOrder[b.name] || 999))
    .map(category => ({
      ...category,
      translated: categoryTranslations[i18n.language]?.[category.name] || category.name
    }));

  // Güvenli localStorage işlemleri
  const safeStorage = {
    set: (key, value) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
      } catch (error) {
        console.warn('Storage error:', error);
        return false;
      }
    },
    get: (key) => {
      try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.warn('Storage error:', error);
        return null;
      }
    }
  };

  // Kategori görsellerini önbellekten al
  useEffect(() => {
    const cachedImages = safeStorage.get('headerCategoryImages');
    if (cachedImages) {
      Object.keys(categoryImages).forEach(id => {
        if (!cachedImages[id]) {
          cachedImages[id] = categoryImages[id];
        }
      });
      safeStorage.set('headerCategoryImages', cachedImages);
    } else {
      safeStorage.set('headerCategoryImages', categoryImages);
    }
  }, [categoryImages]);

  const getImageUrl = useCallback((category) => {
    const cachedImages = safeStorage.get('headerCategoryImages');
    if (!cachedImages?.[category._id] && !categoryImages[category._id]) return '/placeholder.jpg';
    
    const originalUrl = cachedImages?.[category._id] || categoryImages[category._id];
    if (originalUrl.startsWith('http')) return originalUrl;
    
    // URL'den dosya adını al
    const urlParts = originalUrl.split('/');
    const fileName = urlParts[urlParts.length - 1];
    
    // Thumbnail URL'ini oluştur
    return `${process.env.REACT_APP_API_URL}/images/thumbnails/${fileName}`;
  }, [categoryImages]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && onImageUpload) {
      onImageUpload(file);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchQuery.trim());
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (onSearch) {
      onSearch(value.trim());
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    if (onSearch) {
      onSearch('');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm">
      <div className="max-w-[1600px] mx-auto px-4">
        <div className="h-16 flex items-center justify-between gap-4">
          {/* Logo ve Kategori Menüsü */}
          <div className="flex items-center gap-4">
            <Link to="/" className="shrink-0">
              <img src="/murallslogo.png" alt="Muralls Gallery Logo" className="h-10 w-auto object-contain" />
            </Link>

            {/* Kategori Menüsü - Desktop */}
            <div className="hidden md:block relative">
              <button
                onClick={() => setIsCategoryMenuOpen(!isCategoryMenuOpen)}
                onBlur={() => setTimeout(() => setIsCategoryMenuOpen(false), 200)}
                className="flex items-center gap-2 px-4 py-2 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <span className="text-sm font-medium text-gray-700">{t('common.categories')}</span>
                <ChevronDown className={`w-4 h-4 text-gray-500 transition-transform ${isCategoryMenuOpen ? 'rotate-180' : ''}`} />
              </button>

              {/* Kategori Dropdown */}
              {isCategoryMenuOpen && (
                <div className="absolute top-full left-0 mt-2 w-[1000px] max-h-[600px] overflow-y-auto bg-white rounded-xl shadow-xl border border-gray-100 transform -translate-x-1/4">
                  <div className="p-4">
                    <div className="grid grid-cols-6 gap-2">
                      {sortedCategories.map((category) => (
                        <Link
                          key={category._id}
                          to={`/category/${category._id}`}
                          className="group relative aspect-[3/2] overflow-hidden rounded-lg hover:shadow-lg transition-all"
                          onClick={() => setIsCategoryMenuOpen(false)}
                        >
                          <div className="absolute inset-0">
                            <img
                              src={getImageUrl(category)}
                              alt={category.translated}
                              className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                              loading="lazy"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/placeholder.jpg';
                              }}
                            />
                            {/* Overlay with gradient */}
                            <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent opacity-60 transition-opacity duration-300 group-hover:opacity-75" />
                            
                            {/* Category Name */}
                            <div className="absolute inset-0 flex items-center p-2">
                              <div className="bg-white/10 backdrop-blur-sm rounded-lg p-2 transform scale-95 transition-transform duration-500 group-hover:scale-100 w-full">
                                <h2 className="text-sm font-bold text-white text-center">
                                  {category.translated}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Search Bar - Hidden on Mobile */}
          <form onSubmit={handleSearch} className="hidden md:block flex-1 max-w-xl">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={t('common.searchPlaceholder')}
                className="w-full px-4 py-2 pl-10 pr-10 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:bg-white transition-all"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
              {searchQuery && (
                <button
                  type="button"
                  onClick={handleClearSearch}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              )}
            </div>
          </form>

          {/* Right Side Menu */}
          <div className="flex items-center gap-2">
            {/* Language Switcher */}
            <div className="relative group">
              <button className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100 transition-colors">
                <div className="w-6 h-4 overflow-hidden rounded shadow-sm">
                  {languages.find(lang => lang.code === i18n.language)?.flag()}
                </div>
              </button>
              
              <div className="absolute right-0 mt-2 w-44 py-2 bg-white rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform translate-y-1 group-hover:translate-y-0">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => changeLanguage(lang.code)}
                    className={`w-full px-4 py-2 text-left flex items-center gap-3 hover:bg-gray-50 transition-colors ${
                      i18n.language === lang.code ? 'bg-gray-50' : ''
                    }`}
                  >
                    <div className="w-6 h-4 overflow-hidden rounded shadow-sm">
                      {lang.flag()}
                    </div>
                    <span className="text-sm font-medium text-gray-700">{lang.name}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Image Upload Button - Hidden on Mobile */}
            <div className="relative hidden md:block">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors cursor-pointer"
              >
                <ImagePlus className="w-5 h-5 text-gray-700" />
                <span className="text-sm font-medium text-gray-700">{t('common.tryDesign')}</span>
              </label>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <Menu className="w-6 h-6 text-gray-700" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div 
            className="fixed inset-0 bg-black/50 z-40" 
            onClick={toggleMenu}
          />
          
          {/* Menu Panel */}
          <div className="fixed right-0 top-0 bottom-0 w-[280px] bg-white z-50 shadow-xl">
            <div className="flex flex-col h-full">
              {/* Header */}
              <div className="h-16 flex items-center justify-end px-4 border-b">
                <button
                  onClick={toggleMenu}
                  className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <X className="w-6 h-6 text-gray-700" />
                </button>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto">
                {/* Image Upload */}
                <div className="p-4 border-b">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                    id="mobile-image-upload"
                  />
                  <label
                    htmlFor="mobile-image-upload"
                    className="flex items-center gap-2 px-4 py-3 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors cursor-pointer w-full"
                  >
                    <ImagePlus className="w-5 h-5 text-gray-700" />
                    <span className="text-sm font-medium text-gray-700">{t('common.tryDesign')}</span>
                  </label>
                </div>

                {/* Search */}
                <div className="p-4 border-b">
                  <form onSubmit={handleSearch}>
                    <div className="relative">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder={t('common.searchPlaceholder')}
                        className="w-full px-4 py-2 pl-10 pr-10 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:bg-white transition-all"
                      />
                      <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                      {searchQuery && (
                        <button
                          type="button"
                          onClick={handleClearSearch}
                          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
                        >
                          <X className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  </form>
                </div>

                {/* Categories */}
                <div className="p-4">
                  <h3 className="text-sm font-medium text-gray-500 mb-2">{t('common.categories')}</h3>
                  <div className="grid grid-cols-2 gap-2">
                    {sortedCategories.map((category) => (
                      <Link
                        key={category._id}
                        to={`/category/${category._id}`}
                        onClick={toggleMenu}
                        className="group relative aspect-[3/2] overflow-hidden rounded-lg hover:shadow-lg transition-all"
                      >
                        <div className="absolute inset-0">
                          <img
                            src={getImageUrl(category)}
                            alt={category.translated}
                            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                            loading="lazy"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/placeholder.jpg';
                            }}
                          />
                          {/* Overlay with gradient */}
                          <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent opacity-60 transition-opacity duration-300 group-hover:opacity-75" />
                          
                          {/* Category Name */}
                          <div className="absolute inset-0 flex items-center p-2">
                            <div className="bg-white/10 backdrop-blur-sm rounded-lg p-2 transform scale-95 transition-transform duration-500 group-hover:scale-100 w-full">
                              <h2 className="text-xs font-bold text-white text-center">
                                {category.translated}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
}

export default Header;